OwError.SYSTEM.SENTRY = 'sentry';

class OwErrorSystemSentry {
  constructor() {
    this.name = OwError.SYSTEM.SENTRY;
    OwError.addSystem(this);
  }

  initialConfig = {
    beforeSend(event, hint) {
      event = OwErrorSystemSentry.setTempData(event);
      return event;
    }
  }

  init(config) {
    Sentry.init({...config, ...this.initialConfig});
  }

  sendError({error}) {
    Sentry.captureException(error)
  }

  sendMessage({message, level}) {
    Sentry.captureMessage(message, {
      level,
    })
  }

  static setTempData(event) {
    if (!event.contexts) {
      event.contexts = {};
    }

    event = OwErrorSystemSentry.setTempUser(event);
    event = OwErrorSystemSentry.setTempContextResolution(event);

    return event;
  }

  static setTempContextResolution(event) {
    const resolution = OwError.getResolution();

    if (resolution) {
      event.contexts.resolution = resolution;
    }

    return event;
  }

  static setTempUser(event) {
    const user = OwError.getUser();

    if (user) {
      event.user = {
        id: user.user_id
      }
    }

    return event;
  }
}

new OwErrorSystemSentry();
