
window.addEventListener('load', () => {
  window.owError = new OwError({
    config: {
      usingSystemName: OwError.SYSTEM.SENTRY,
      
    [OwError.SYSTEM.SENTRY]: {
      config: {
        dsn: "https://135a30be63c646bcb7b8e749a0ed6d7f@trainapp.pl/error-logs/71",
        tracesSampleRate: 0.2,
        integrations: [new Sentry.Integrations.BrowserTracing()],
        debug: false,
        environment: 'production',
        release: 'media-expert-train-app-frontend@5726853d',
        ignoreErrors: [
          'Non-Error exception captured',
        ],
      },
    },
  
    }
  });
});
